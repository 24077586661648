<template>
  <b-container>
    <b-modal
      v-model="DeleteEducation"
      :title="$t('Confirm Delete')"
      :ok-title="$t('Delete')"
      ok-variant="danger"
      modal-class="modal-danger"
      cancel-variant="outline-secondary"
      :cancel-title="$t('Cancel')"
      centered
      @ok="acceptEducationAlert"
    >
      <b-card-text>
        {{ $t('Are you sure you want to delete this Education?') }}
      </b-card-text>
    </b-modal>
    <b-modal
      v-model="DeleteLanguage"
      :title="$t('Confirm Delete')"
      :ok-title="$t('Delete')"
      ok-variant="danger"
      modal-class="modal-danger"
      cancel-variant="outline-secondary"
      :cancel-title="$t('Cancel')"
      centered
      @ok="acceptLanguageAlert"
    >
      <b-card-text>
        {{ $t('Are you sure you want to delete this Language?') }}
      </b-card-text>
    </b-modal>
    <b-modal
      v-model="DeleteInterest"
      :title="$t('Confirm Delete')"
      :ok-title="$t('Delete')"
      ok-variant="danger"
      modal-class="modal-danger"
      cancel-variant="outline-secondary"
      :cancel-title="$t('Cancel')"
      centered
      @ok="acceptInterestAlert"
    >
      <b-card-text>
        {{ $t('Are you sure you want to delete this Interest?') }}
      </b-card-text>
    </b-modal>
    <b-modal
      v-model="DeleteVolunteeringWork"
      :title="$t('Confirm Delete')"
      :ok-title="$t('Delete')"
      ok-variant="danger"
      modal-class="modal-danger"
      cancel-variant="outline-secondary"
      :cancel-title="$t('Cancel')"
      centered
      @ok="acceptVolunteeringWorkAlert"
    >
      <b-card-text>
        {{ $t('Are you sure you want to delete this Volunteering Work?') }}
      </b-card-text>
    </b-modal>
    <b-modal
      v-model="DeleteExperience"
      :title="$t('Confirm Delete')"
      :ok-title="$t('Delete')"
      ok-variant="danger"
      modal-class="modal-danger"
      cancel-variant="outline-secondary"
      :cancel-title="$t('Cancel')"
      centered
      @ok="acceptExperienceAlert"
    >
      <b-card-text>
        {{ $t('Are you sure you want to delete this Experience?') }}
      </b-card-text>
    </b-modal>
    <b-modal
      v-model="DeleteOccupations"
      :title="$t('Confirm Delete')"
      :ok-title="$t('Delete')"
      ok-variant="danger"
      modal-class="modal-danger"
      cancel-variant="outline-secondary"
      :cancel-title="$t('Cancel')"
      centered
      @ok="acceptOccupationAlert"
    >
      <b-card-text>
        {{ $t('Are you sure you want to delete this Desired Job?') }}
      </b-card-text>
    </b-modal>
    <add-education v-model="AddEducation" />
    <add-volunteering-work v-model="AddVolunteeringWork" />
    <add-experience v-model="AddExperience" />
    <add-interest v-model="AddInterest" />
    <add-occupations v-model="AddOccupations" />
    <add-language v-model="AddLanguage" />
    <b-row>
      <b-col>
        <b-card>
          <b-card-title>
            <b-row>
              <b-col cols="12">
                {{ $t('Profile') }}
                {{ UserId }}
              </b-col>
            </b-row>
          </b-card-title>
          <b-card-body>
            <b-row align-v="center">
              <b-col
                cols="12"
                lg="4"
                class="d-flex justify-content-center"
              >
                <vx-upload
                  v-model="UserData.avatar_id"
                  class="mt-2"
                />
              </b-col>
              <b-col
                cols="12"
                lg="8"
              >
                <b-row>
                  <b-col
                    cols="12"
                    lg="6"
                  >
                    <vx-input
                      v-model="UserData.first_name"
                      class="mt-1"
                      :label="$t('First Name')"
                      :errors="errors.first_name"
                    />
                  </b-col>
                  <b-col
                    cols="12"
                    lg="6"
                  >
                    <vx-input
                      v-model="UserData.last_name"
                      class="mt-1"
                      :label="$t('Last Name')"
                      :errors="errors.last_name"
                    />
                  </b-col>
                  <b-col
                    cols="12"
                    lg="6"
                  >
                    <vx-input
                      v-model="UserData.phone"
                      class="mt-1"
                      type="number"
                      :label="$t('Phone')"
                      :errors="errors.phone"
                    />
                  </b-col>
                  <b-col
                    cols="12"
                    lg="6"
                  >
                    <vx-input
                      v-model="UserData.job_address"
                      class="mt-1"
                      :label="$t('Desired Job Location')"
                      :errors="errors.job_address"
                    />
                  </b-col>
                  <b-col
                    cols="12"
                    lg="6"
                  >
                    <vx-input
                      v-model="UserData.qualifications"
                      class="mt-1"
                      :label="$t('Qualifications')"
                      :errors="errors.qualifications"
                    />
                  </b-col>
                  <b-col
                    cols="12"
                    lg="6"
                  >
                    <vx-input
                      v-model="UserData.institution_name"
                      class="mt-1"
                      :label="$t('Institution Name')"
                      :errors="errors.institution_name"
                    />
                  </b-col>
                  <b-col
                    cols="12"
                    lg="6"
                  >
                    <vs-textarea
                      v-model="UserData.profile_summary"
                      label="Profile Summary"
                    />
                  </b-col>
                  <b-col
                    cols="12"
                    lg="6"
                  >
                    <vs-textarea
                      v-model="UserData.additional_information"
                      label="Additional Information"
                    />
                  </b-col>
                  <b-col
                    cols="12"
                    lg="6"
                  >
                    <vs-textarea
                      v-model="UserData.address"
                      label="Address"
                    />
                  </b-col>
                  <b-col
                    cols="12"
                    lg="6"
                  >
                    <vs-textarea
                      v-model="UserData.ex_address"
                      label="Second Address"
                    />
                  </b-col>
                  <b-col
                    cols="12"
                    lg="6"
                  >
                    <vs-select
                      v-model="UserData.availability"
                      :placeholder="$t('Availability')"
                      class="selectExample mb-1"
                      style="width: 100%"
                      :dir="$vs.rtl ? 'rtl' : 'ltr'"
                    >
                      <vs-select-item
                        v-for="(item,index) in status"
                        :key="index"
                        :value="item.value"
                        :text="item.text"
                      />
                    </vs-select>
                    <vs-alert
                      v-show="errors.availability"
                      v-if="errors.availability"
                      color="danger"
                      active="true"
                    >
                      {{ $t(errors.availability[0]) }}
                    </vs-alert>
                  </b-col>
                  <b-col
                    cols="12"
                    lg="6"
                  >
                    <vs-select
                      v-model="UserData.employment_type"
                      :placeholder="$t('Employment Type')"
                      class="selectExample mb-1"
                      style="width: 100%"
                      :dir="$vs.rtl ? 'rtl' : 'ltr'"
                    >
                      <vs-select-item
                        v-for="(item,index) in employment_type"
                        :key="index"
                        :value="item.value"
                        :text="item.text"
                      />
                    </vs-select>
                    <vs-alert
                      v-show="errors.employment_type"
                      v-if="errors.employment_type"
                      color="danger"
                      active="true"
                    >
                      {{ $t(errors.employment_type[0]) }}
                    </vs-alert>
                  </b-col>
                  <b-col
                    cols="12"
                    lg="6"
                  >
                    <b-form-checkbox
                      v-model="UserData.work_permit"
                      class="custom-control-primary"
                    >
                      Do you have work premit
                    </b-form-checkbox>
                    <b-form-checkbox
                      v-model="UserData.refugee_status"
                      class="custom-control-primary mt-1"
                    >
                      Do you have Refugee Status
                    </b-form-checkbox>
                  </b-col>
                </b-row>
              </b-col>
              <b-col
                class="mt-1"
                vs-w="12"
                align="end"
              >
                <vs-button
                  color="success"
                  class="mr-2 mb-2"
                  type="gradient"
                  @click="UpdateProfile"
                >
                  {{ $t('Confirm') }}
                </vs-button>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <b-card>
      <b-card-title>
        {{ $t('CV') }}
      </b-card-title>
      <b-card-body>
        <b-row align-h="center">

          <b-col
            cols="12"
            lg="4"
          >
            <vx-upload
              v-model="UserData.avatar_id"
            />
          </b-col>
          <b-col
            cols="12"
            lg="4"
            class="mt-2"
          >
            <h1> {{ UserData.first_name + ' ' + UserData.last_name }}</h1>
            <h6>{{ UserData.qualifications }}</h6>
          </b-col>
          <b-col
            cols="12"
            lg="4"
            class="mt-2"
          >
            <b-row>
              <b-col cols="12">
                <fa-icon
                  class="mr-1"
                  color="primary"
                  pack="fas"
                  icon="phone"
                />
                <span>{{ UserData.phone }}</span>
              </b-col>
              <b-col cols="12">
                <fa-icon
                  class="mr-1"
                  color="primary"
                  pack="fas"
                  icon="map-marker"
                />
                <span>{{ UserData.address }}</span>
              </b-col>
              <b-col cols="12">
                <fa-icon
                  class="mr-1"
                  color="primary"
                  pack="fas"
                  icon="envelope-square"
                />
                <span>{{ UserData.email }}</span>
              </b-col>
            </b-row>

          </b-col>
          <b-col
            cols="12"
            class="mt-1 mb-1"
          >
            <hr>
          </b-col>
          <b-col
            cols="8"
          >
            <h2>Profile Summary</h2>
            <p>
              {{ UserData.profile_summary }}
            </p>
          </b-col>
          <b-col
            cols="4"
          >
            <cv-file-component />
          </b-col>
          <b-col
            cols="12"
            class="mt-1 mb-1"
          >
            <hr>
          </b-col>
          <b-col
            cols="12"
            lg="8"
          >
            <b-row>

              <b-col
                cols="12"
                class="card_border"
              >
                <h2>Experience</h2>
                <b-row
                  v-for="(tr, indextr) in UserData.experiences"
                  :key="indextr"
                  class="mt-2 mb-2 item"
                >
                  <b-col
                    cols="12"
                    class="mb-1"
                    lg="6"
                  >
                    <h5><b>{{ tr.title }}</b></h5>
                    <span>{{ tr.description }}</span>
                  </b-col>
                  <b-col
                    cols="12"
                    class="mb-1"
                    lg="6"
                  >
                    <b-row>
                      <b-col cols="6">
                        <vx-badge
                          v-if="tr.from && tr.to"
                          color="light-primary"
                        >
                          {{ tr.from }} - {{ tr.to }}
                        </vx-badge>
                        <vx-badge
                          v-else-if="tr.from"
                          color="light-primary"
                        >
                          {{ tr.from }}
                        </vx-badge>
                        <vx-badge
                          v-else
                          color="light-primary"
                        >
                          No Date specified
                        </vx-badge>
                      </b-col>
                      <b-col
                        cols="6"
                        class="d-flex justify-content-center"
                      >
                        <a @click="ExperienceId=tr.id,DeleteExperience=true">
                          <fa-icon
                            color="danger"
                            pack="fas"
                            icon="trash-alt"
                          />
                        </a>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <b-row class="mt-2 mb-2">
                  <b-col
                    cols="12"
                  >
                    <b-button
                      variant="outline-primary"
                      class="btn-icon"
                      @click="AddExperience=true"
                    >
                      <fa-icon
                        color="primary"
                        pack="fas"
                        icon="plus"
                      />
                    </b-button>
                  </b-col>
                </b-row>
              </b-col>
              <b-col
                cols="12"
                class="mt-1 mr-1"
              >
                <hr>
              </b-col>
              <b-col
                cols="12"
                class="card_border"
              >
                <h2>Education</h2>
                <b-row
                  v-for="(tr, indextr) in UserData.educations"
                  :key="indextr"
                  class="mt-2 mb-3 item"
                >
                  <b-col
                    cols="12"
                    class="mb-1"
                    lg="6"
                  >
                    <h5><b>{{ tr.title }}</b></h5>
                    <span>{{ tr.institution }}</span>
                  </b-col>
                  <b-col
                    cols="12"
                    class="mb-1"
                    lg="6"
                  >
                    <b-row>
                      <b-col cols="6">
                        <vx-badge
                          v-if="tr.start && tr.finish"
                          color="light-primary"
                        >
                          {{ tr.start }} - {{ tr.finish }}
                        </vx-badge>
                        <vx-badge
                          v-else-if="tr.start"
                          color="light-primary"
                        >
                          {{ tr.start }}
                        </vx-badge>
                        <vx-badge
                          v-else
                          color="light-primary"
                        >
                          No Date specified
                        </vx-badge>
                      </b-col>
                      <b-col
                        cols="6"
                        class="d-flex justify-content-center"
                      >
                        <a @click="DeleteEducation=true,EducationId=tr.id">
                          <fa-icon
                            color="danger"
                            pack="fas"
                            icon="trash-alt"
                          />
                        </a>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <b-row class="mt-2 mb-2">
                  <b-col
                    cols="12"
                  >
                    <b-button
                      variant="outline-primary"
                      class="btn-icon"
                      @click="AddEducation=true"
                    >
                      <fa-icon
                        color="primary"
                        pack="fas"
                        icon="plus"
                      />
                    </b-button>
                  </b-col>
                </b-row>
              </b-col>
              <b-col
                cols="12"
                class="mt-1 mr-1"
              >
                <hr>
              </b-col>
              <b-col
                cols="12"
                class="card_border"
              >
                <h2>Volunteering Work</h2>
                <b-row
                  v-for="(tr, indextr) in UserData.volunteering_works"
                  :key="indextr"
                  class="mt-2 mb-3 item"
                >
                  <b-col
                    cols="12"
                    class="mb-1"
                    lg="6"
                  >
                    <h5><b>{{ tr.title }}</b></h5>
                    <span>{{ tr.description }}</span>
                  </b-col>
                  <b-col
                    cols="12"
                    class="mb-1"
                    lg="6"
                  >
                    <b-row>
                      <b-col cols="6">
                        <vx-badge
                          v-if="tr.from && tr.to"
                          color="light-primary"
                        >
                          {{ tr.from }} - {{ tr.to }}
                        </vx-badge>
                        <vx-badge
                          v-else-if="tr.from"
                          color="light-primary"
                        >
                          {{ tr.from }}
                        </vx-badge>
                        <vx-badge
                          v-else
                          color="light-primary"
                        >
                          No Date specified
                        </vx-badge>
                      </b-col>
                      <b-col
                        cols="6"
                        class="d-flex justify-content-center"
                      >
                        <a @click="VolunteeringWorkId=tr.id,DeleteVolunteeringWork=true">
                          <fa-icon
                            color="danger"
                            pack="fas"
                            icon="trash-alt"
                          />
                        </a>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>

                <b-row class="mt-2 mb-4">
                  <b-col
                    cols="12"
                  >
                    <b-button
                      variant="outline-primary"
                      class="btn-icon"
                      @click="AddVolunteeringWork=true"
                    >
                      <fa-icon
                        color="primary"
                        pack="fas"
                        icon="plus"
                      />
                    </b-button>
                  </b-col>
                </b-row>
              </b-col>
              <b-col
                cols="12"
                class="mt-1 mb-1"
              >
                <hr>
              </b-col>
              <b-col
                cols="12"
                class="card_border"
              >
                <h2>Additional Information</h2>
                <p class="item">
                  {{ UserData.additional_information }}
                </p>
              </b-col>
            </b-row>
          </b-col>
          <b-col
            cols="12"
            lg="4"
          >
            <b-row>
              <b-col
                cols="12"
              >
                <h2>Interests</h2>
                <b-row
                  class="mt-2 mb-2"
                >
                  <b-col
                    v-for="(tr, indextr) in UserData.interests"
                    :key="indextr"
                    cols="4"
                    class="mt-1"
                  >
                    <vx-badge
                      class="visibleDelete"
                      color="light-success"
                    >
                      {{ tr.title }}
                      <a @click="InterestId=tr.id,DeleteInterest=true">
                        <fa-icon
                          pack="fas"
                          icon="times"
                          color="danger"
                        />
                      </a>
                    </vx-badge>
                  </b-col>
                </b-row>
                <b-row class="mt-2 mb-2">
                  <b-col
                    cols="12"
                  >
                    <b-button
                      variant="outline-primary"
                      class="btn-icon"
                      @click="AddInterest=true"
                    >
                      <fa-icon
                        color="primary"
                        pack="fas"
                        icon="plus"
                      />
                    </b-button>
                  </b-col>
                </b-row>
              </b-col>
              <b-col
                cols="12"
                class="mt-1 mr-1"
              >
                <hr>
              </b-col>
              <b-col
                cols="12"
              >
                <h2>Desired Jobs</h2>
                <b-row class="mt-2 mb-2">
                  <b-col
                    v-for="(tr, indextr) in UserData.occupations"
                    :key="indextr"
                    cols="4"
                    class="mt-1"
                  >
                    <vx-badge
                      class="visibleDelete"
                      color="light-success"
                    >
                      {{ tr.title }}
                      <a @click="OccupationId=tr.id,DeleteOccupations=true">
                        <fa-icon
                          pack="fas"
                          icon="times"
                          color="danger"
                        />
                      </a>
                    </vx-badge>
                  </b-col>
                </b-row>
                <b-row class="mt-2 mb-2">
                  <b-col
                    cols="12"
                  >
                    <b-button
                      variant="outline-primary"
                      class="btn-icon"
                      @click="AddOccupations=true"
                    >
                      <fa-icon
                        color="primary"
                        pack="fas"
                        icon="plus"
                      />
                    </b-button>
                  </b-col>
                </b-row>
              </b-col>
              <b-col
                cols="12"
                class="mt-1 mr-1"
              >
                <hr>
              </b-col>
              <b-col
                cols="12"
              >
                <h2>Languages</h2>
                <b-row
                  v-for="(tr, indextr) in UserData.languages"
                  :key="indextr"
                  class="mt-2 mb-2"
                >
                  <b-col
                    cols="6"
                  >
                    <vx-badge
                      class="visibleDelete"
                      color="light-success"
                    >
                      {{ tr.language }}
                      <a @click="LanguageId=tr.id , DeleteLanguage=true">
                        <fa-icon
                          pack="fas"
                          icon="times"
                          color="danger"
                        />
                      </a>
                    </vx-badge>
                  </b-col>
                  <b-col
                    cols="6"
                  >
                    {{ tr.status }}
                  </b-col>
                </b-row>
                <b-row class="mt-2 mb-2">
                  <b-col
                    cols="12"
                  >
                    <b-button
                      variant="outline-primary"
                      class="btn-icon"
                      @click="AddLanguage=true"
                    >
                      <fa-icon
                        color="primary"
                        pack="fas"
                        icon="plus"
                      />
                    </b-button>
                  </b-col>
                </b-row>
              </b-col>
              <b-col
                cols="12"
                class="mt-1 mr-1"
              >
                <hr>
              </b-col>
              <b-col
                cols="12"
              >
                <h2>Other</h2>
                <b-row class="mt-2 mb-2">
                  <b-col cols="6">
                    <vx-badge
                      color="light-success"
                    >
                      Work Premit
                    </vx-badge>
                  </b-col>
                  <b-col cols="6">
                    <fa-icon
                      v-if="UserData.work_permit"
                      pack="fas"
                      icon="check"
                      color="success"
                    />
                    <fa-icon
                      v-else
                      pack="fas"
                      icon="times"
                      color="danger"
                    />
                  </b-col>
                  <b-col
                    class="mt-1"
                    cols="6"
                  >
                    <vx-badge
                      color="light-success"
                    >
                      Refugee Status
                    </vx-badge>
                  </b-col>
                  <b-col
                    class="mt-1"
                    cols="6"
                  >
                    <fa-icon
                      v-if="UserData.refugee_status"
                      pack="fas"
                      icon="check"
                      color="success"
                    />
                    <fa-icon
                      v-else
                      pack="fas"
                      icon="times"
                      color="danger"
                    />
                  </b-col>
                  <b-col
                    class="mt-1"
                    cols="6"
                  >
                    <vx-badge
                      color="light-success"
                    >
                      Employment Type
                    </vx-badge>
                  </b-col>
                  <b-col
                    class="mt-1"
                    cols="6"
                  >
                    <vx-badge color="light-success">
                      {{ UserData.employment_type }}
                    </vx-badge>
                  </b-col>
                  <b-col
                    class="mt-1"
                    cols="6"
                  >
                    <vx-badge
                      color="light-success"
                    >
                      Availability
                    </vx-badge>
                  </b-col>
                  <b-col
                    class="mt-1"
                    cols="6"
                  >
                    <vx-badge :color="UserData.availability==='available'?'light-success':'light-danger'">
                      {{ UserData.availability }}
                    </vx-badge>
                  </b-col>
                  <b-col
                    class="mt-1"
                    cols="6"
                  >
                    <vx-badge
                      color="light-success"
                    >
                      Desired Job Location
                    </vx-badge>
                  </b-col>
                  <b-col
                    class="mt-1"
                    cols="6"
                  >
                    <vx-badge color="light-success">
                      {{ UserData.job_address }}
                    </vx-badge>
                  </b-col>
                </b-row>
              </b-col>

            </b-row>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </b-container>
</template>

<script>
/* eslint-disable */

import flatPickr from 'vue-flatpickr-component'
import AddEducation from "@/views/dashboard/person/components/AddEducation.vue";
import AddVolunteeringWork from "@/views/dashboard/person/components/AddVolunteeringWork.vue";
import AddExperience from "@/views/dashboard/person/components/AddExperience.vue";
import AddInterest from "@/views/dashboard/person/components/AddInterest.vue";
import AddOccupations from "@/views/dashboard/person/components/AddOccupation.vue";
import AddLanguage from "@/views/dashboard/person/components/AddLanguage.vue";
import CvFileComponent from "@/views/dashboard/person/components/CvFileComponent.vue";

export default {
  name: "EmployeeProfile",
  components: {
    CvFileComponent,
    AddLanguage, flatPickr,
    AddOccupations, AddInterest, AddExperience, AddVolunteeringWork, AddEducation,
  },
  data: () => ({
    UserData: {},
    UserId: null,
    errors: [],
    OccupationId: '',
    LanguageId: '',
    InterestId: '',
    EducationId: '',
    ExperienceId: '',
    VolunteeringWorkId: '',
    AddEducation: false,
    DeleteEducation: false,
    AddVolunteeringWork: false,
    DeleteVolunteeringWork: false,
    AddExperience: false,
    DeleteExperience: false,
    AddInterest: false,
    DeleteInterest: false,
    AddOccupations: false,
    DeleteOccupations: false,
    AddLanguage: false,
    DeleteLanguage: false,
    employment_type: [
      {text: 'Full Time', value: 'full_time'},
      {text: 'Part Time', value: 'part_time'},
      {text: 'Remote', value: 'remote'},
    ],
    status: [
      {text: 'Available', value: 'available'},
      {text: 'Unavailable', value: 'unavailable'},
    ],
  }),
  computed: {
    userData() {
      return this.$store.getters['employee/person']
    },
  },
  watch: {
    userData(val) {
      this.UserData = { ...val }
    },
  },
  methods: {
    UpdateProfile() {
      this.errors = []
      this.$store.dispatch('employee/update_person', {user_id:this.UserData.id , UserData:this.UserData}).then(() => {
      }).catch(error => {
        if (error.response.data.errors) this.errors = error.response.data.errors
      })
    },
    acceptLanguageAlert() {
      this.$store.dispatch('employee/delete_language', this.LanguageId)
      this.getData()
    },
    acceptOccupationAlert() {
      this.$store.dispatch('employee/delete_occupation', this.OccupationId)
      this.getData()
    },
    acceptInterestAlert() {
      this.$store.dispatch('employee/delete_interest', this.InterestId)
      this.getData()
    },
    acceptExperienceAlert() {
      this.$store.dispatch('employee/delete_experience', this.ExperienceId)
      this.getData()
    },
    acceptVolunteeringWorkAlert() {
      this.$store.dispatch('employee/delete_volunteering_work', this.VolunteeringWorkId)
      this.getData()
    },
    acceptEducationAlert() {
      this.$store.dispatch('employee/delete_education', this.EducationId)
      this.getData()
    },
    getData(){
      this.$store.dispatch('employee/GetPersonData',this.$route.params.userId)
    }
  },
  created() {
   this.getData()
    this.UserId=this.$route.params.userId
  },
}
</script>

<style scoped>
.visibleDelete a {
  visibility: hidden;
  position: absolute;
}

.visibleDelete:hover a {
  position: relative;
  margin-left: 2px;
  visibility: visible;
}

.card_border .item {
  border: 1px solid transparent;
  padding: 10px;
}

.item a {
  visibility: hidden;
  position: absolute;
}

.item:hover a {
  position: relative;
  visibility: visible;
}

.card_border .item:hover {
  border-radius: 10px;
  border: 1px solid orange;
}
</style>
