<template>
  <div>
    <b-modal
      ref="my-modal"
      v-model="IsPopupActiveLocal"
      :title="$t('Add Education')"
      hide-footer
      @hidden="Reset"
      @close="Reset"
    >
      <div class="mb-2">
        {{ $t('Fill up your Education information') }}
      </div>
      <div class="con-exemple-prompt">
        <b-row>
          <b-col cols="12">
            <vx-input
              v-model="FillData.institution"
              :errors="errors.institution"
              label="institution"
            />
          </b-col>
          <b-col cols="12">
            <vx-input
              v-model="FillData.title"
              label="Department"
              :errors="errors.title"
            />
          </b-col>
          <b-col cols="12">
            <b-form-group>
              <h5 class="font-weight-bold">
                Start Date
              </h5>
              <flat-pickr
                v-model="FillData.start"
                class="form-control"
                :config="{ dateFormat: 'Y-m-d'}"
              />
            </b-form-group>
            <vs-alert
              v-show="errors.start"
              v-if="errors.start"
              color="danger"
              active="true"
            >
              {{ $t(errors.start[0]) }}
            </vs-alert>
          </b-col>
          <b-col cols="12">
            <b-form-group>
              <h5 class="font-weight-bold">
                Start Date
              </h5>
              <flat-pickr
                v-model="FillData.finish"
                class="form-control"
              />
            </b-form-group>
            <vs-alert
              v-show="errors.finish"
              v-if="errors.finish"
              color="danger"
              active="true"
            >
              {{ $t(errors.finish[0]) }}
            </vs-alert>
          </b-col>
          <b-col cols="12">
            <hr>
          </b-col>
          <b-col
            cols="12"
            class="d-flex justify-content-end"
          >
            <b-button
              variant="gradient-success"
              @click="Add"
            >
              {{ $t('Add') }}aaaaa
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-modal>
  </div>
</template>

<script>

import flatPickr from 'vue-flatpickr-component'
/* eslint-disable */
export default {
  name: "AddEducation",
  components: {
    flatPickr,
  },
  props: ['value'],

  data: () => ({
    FillData:{
      title:'',
      institution:'',
      start:'',
      finish:'',
      user_id:null
    },
    errors:[],
  }),
  computed: {
    IsPopupActiveLocal: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },

    },
  },
  methods: {
    Reset(){
      this.IsPopupActiveLocal = false
      this.FillData.finish=''
      this.FillData.institution=''
      this.FillData.start=''
      this.FillData.title=''
      this.errors=[]
    },
    Add() {
      this.$store.dispatch('employee/add_education',this.FillData).then(() => {
        this.Reset()
        this.$store.dispatch('employee/GetPersonData',this.$route.params.userId)
      }).catch(error => {
        if (error.response.data.errors) this.errors = error.response.data.errors
      })
    }
  },
  created() {
    this.FillData.user_id=this.$route.params.userId
  }
}
</script>
<style lang="scss">
@import './src/@core/scss/vue/libs/vue-flatpicker.scss';
</style>
